import * as api from '@app/api.js'

export default {
  computed: {
    back_link() {
      if (this.$route.query.back == 'recipient')
        return `/app/recipients/${this.content_asset.recipient.id}`
      else if (this.$route.query.back == 'aggregate')
        return '`/app/surveys/${this.content_asset.survey.id}/results?tab=1`'
      else if (this.$route.query.back == 'show')
        return `/app/content_assets/${this.content_asset.id}`
    },
  },
  methods: { ...api },
}
